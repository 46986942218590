export interface ICoupling {
  id: string;
  scope: CouplingScope | null;
  outbound?: boolean;
  clientId: string | null;
  orgId: string | null;
  createdAt: string | null;
  state: string | null;
  validUntil: string | null;
  user: string | null;
  dataConnectInboundDetails?: IDataConnectInboundDetails;
}

export interface PartnerAdditionalProperty {
  key: string;
  value: any;
}

export interface IPartner {
  id: string;
  name?: string | null;
  description: string | null;
  shortDescription: string | null;
  contact: string | null;
  logoUrl: string | null;
  oftInboundUnsupported: boolean | null;
  oftOutboundUnsupported: boolean | null;
  // list of country codes (as defined in country.model.d.ts.map)
  countries: string[];
  partnerUrl: string;
  scopes: CouplingScope[];
  additionalProperties: PartnerAdditionalProperty[];
}

export const PARTNER_POINTDATA_AVAILABLE_DATASETS_KEY = 'scopes.point-data.datasets';
export const DATACONNECT_INBOUND_SCOPE = 'dataconnect-inbound';
export const TRACKER_SCOPE = 'tracker';

export interface IConnection {
  id: string;
  partner: IPartner;
  couplings: ICoupling[];
}

export interface IConnectionWithScopedCouplings extends IConnection {
  couplingForScope: Map<string, ICoupling[] | null | undefined>;
}

export interface IConsent {
  text: string | null;
  givenAt: string | null;
  scope: string | null;
  version: number;
  consentGiven: boolean | null;
}

export interface IConsentForCoupling {
  couplingId: string;
  consent: IConsent;
}

export interface ConnectionsResponse {
  content: IConnection[];
}

export interface SortedConnections {
  inactive: IConnection[];
  active: IConnection[];
}

export interface IOrganizationMember {
  idpUser: string;
  givenName: string;
  familyName: string;
  email: string;
  profilePicture: string;
}

export interface ICouplingScope {
  key: string;
  value: ICoupling[];
}

export interface IDataConnectInboundDetails {
  container: IDataConnectInboundContainer;
  equipment: IDataConnectInboundEquipment[];
}

export interface IDataConnectInboundCouplingList {
  couplings: IDataConnectInboundCoupling[];
}

export interface IDataConnectInboundCoupling {
  couplingId: string;
  container: IDataConnectInboundContainer;
}

export enum DataConnectEquipmentChangeType {
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
  UPDATED = 'UPDATED',
}

export interface IDataConnectInboundContainer {
  containerId: string;
  name: string;
  equipmentChangesDetectedAt?: string;
  equipmentChanges?: DataConnectEquipmentChangeType[];
  subscription?: IDataConnectInboundSubscription;
}

export interface IDataConnectInboundSubscription {
  lastReactivatedAt?: string;
  createdAt: string;
}

export interface IDataConnectInboundAuthWithConnectionId {
  inboundAuth: IDataConnectInboundAuth;
  connectionId: string;
}

export interface IDataConnectInboundAuth {
  couplingId?: string;
  containers?: IDataConnectInboundAuthContainer[];
  redirectUri: string;
}

export interface IDataConnectInboundAuthContainer {
  containerId: string;
  name: string;
}

export interface IDataConnectInboundContainerPut {
  containerId: string;
  name: string;
  equipment: IDataConnectInboundEquipment[];
  notification: IDataConnectInboundContainerPutNotification;
}

export interface IDataConnectInboundContainerPutNotification {
  emails: string[];
  lang: string;
}

export interface IDataConnectInboundEquipmentList {
  containerId: string;
  equipment: IDataConnectInboundEquipment[];
}

export interface IDataConnectInboundEquipment {
  equipmentId: string;
  name: string;
  make: string;
  type: string;
  model: string;
  pin: string;
  isSelected: boolean;
  state: IDataConnectInboundEquipmentState;
}

export type IDataConnectInboundEquipmentState = 'unmodified' | 'modified' | 'added' | 'removed';

export type CouplingScope = 'oft' | 'data-connect' | 'point-data' | 'dataconnect-inbound' | 'tracker' | 'locations';

export type IDataConnectInboundContainerPutType = 'create' | 'update';
